/* You can add global styles to this file, and also import other style files */

/*Adds Material Theme to project*/
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "angular-material.scss";

/* Default font for Acetech products is Arial */
*:not(mat-icon) {
  font-family: Arial, Helvetica, sans-serif !important;
  color: var(--text);
  caret-color: var(--text) !important;
}

:root {
  --red: #d90915;
  --navy: #0e1b47;
  --blue: #2f80ed;
  --text: #000000;
  --border: #dfdfdf;
  --grey: #7b7b7b;
  --grey-fade: #f1f1f1;
  --green: #42a033;
  --orange: #ffc803;
  --transitionTime: 0.5s;
  --smallNav: 47px;
  --bigNav: 190px;
  // Font Sizes
  --headerFont: 30px;
  --titleFont: 24px;
  --xlFont: 19px;
  --largeFont: 18px;
  --defaultFont: 16px;
  --smallFont: 14px;
  --xsFont: 12px;
}

@media only screen and (max-width: 1450px) {
  :root {
    // Font Sizes
    --headerFont: 26px;
    --titleFont: 20px;
    --xlFont: 17px;
    --largeFont: 16px;
    --defaultFont: 14px;
    --smallFont: 12px;
    --xsFont: 10px;
  }
}

body,
html {
  height: 100%;
  width: 100%;
  overflow-y: hidden !important;
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  height: 100% !important;
}

.OpenSans-SemiboldItalic {
  font-family: opensans-semibolditalic, Arial, Helvetica, sans-serif !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.just-center {
  justify-content: center !important;
}

.pointer {
  cursor: pointer !important;
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.read-only-input {
  cursor: default !important;
  background-color: var(--grey-fade) !important;
}

// Icon colors

.error path {
  color: var(--red) !important;
}

// Icon colors end

hr {
  border: 1px solid #0e1b4727;
}

.hidden {
  visibility: hidden !important;
  -webkit-touch-callout: none !important; /* iOS Safari */
  -webkit-user-select: none !important; /* Safari */
  -khtml-user-select: none !important; /* Konqueror HTML */
  -moz-user-select: none !important; /* Firefox */
  -ms-user-select: none !important; /* Internet Explorer/Edge */
  user-select: none !important; /* Non-prefixed version, currently supported by Chrome and Opera */
}

a {
  color: #9e9e9e;
}

a:hover {
  text-decoration: underline;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.spacer {
  flex: 1 1 auto;
}

.uppercase {
  text-transform: uppercase;
}

/* buttons */

button {
  outline: none !important;
}

.confirm-button {
  background-color: var(--navy) !important;
  border: 1px solid var(--navy) !important;
  outline: none;
  height: 34px;
  line-height: 34px !important;
  width: 112px;
  color: #ffffff !important;
}

.confirm-button .mdc-button__label {
  color: #ffffff !important;
}

.cancel-button {
  background-color: #ffffff;
  outline: none;
  height: 34px;
  line-height: 34px !important;
  border: 1px solid var(--navy) !important;
  width: 112px;
}

/* buttons end */

/* paginator */

.custom-mat-paginator {
  width: 100%;
  background: #f8f8f8;
}

/* paginator End*/

::placeholder {
  color: #0e1b4754 !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #0e1b4754 !important;
}

::-ms-input-placeholder {
  color: #0e1b4754 !important;
}

/* Side nav animation */

.nav-closed {
  margin-left: 70px !important;
}

.nav-open {
  margin-left: 213px !important;
}

.la-ball-scale-multiple.la-3x > div {
  color: var(--navy) !important;
}
// Font Aweseome
@font-face {
  font-family: "open_sansbold";
  src: url(assets/fonts/webfonts/custom-fonts/OpenSans-Bold/opensans-bold-webfont.woff2)
      format("woff2"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-Bold/opensans-bold-webfont.woff)
      format("woff"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-Bold.ttf) format("ttf");
  font-weight: normal;
  font-style: normal;
}

.open_sansBold {
  font-family: open_sansbold, Arial, Helvetica, sans-serif !important;
}

@font-face {
  font-family: "OpenSans-bolditalic";
  src: url(assets/fonts/webfonts/custom-fonts/OpenSans-BoldItalic/opensans-bolditalic.woff2)
      format("woff2"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-BoldItalic/opensans-bolditalic.woff)
      format("woff"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-BoldItalic.ttf)
      format("ttf");
  font-weight: normal;
  font-style: normal;
}
.OpenSans-boldItalic {
  font-family: open_sansbolditalic, Arial, Helvetica, sans-serif !important;
}

@font-face {
  font-family: "OpenSans-extrabold";
  src: url(assets/fonts/webfonts/custom-fonts/OpenSans-ExtraBold/opensans-extrabold.woff2)
      format("woff2"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-ExtraBold/opensans-extrabold.woff)
      format("woff"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-ExtraBold.ttf) format("ttf");
  font-weight: normal;
  font-style: normal;
}

.OpenSans-Extrabold {
  font-family: OpenSans-extrabold, Arial, Helvetica, sans-serif !important;
}

@font-face {
  font-family: "opensans-extrabolditalic";
  src: url(assets/fonts/webfonts/custom-fonts/OpenSans-ExtraBoldItalic/opensans-extrabolditalic.woff2)
      format("woff2"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-ExtraBoldItalic/opensans-extrabolditalic.woff)
      format("woff"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-ExtraBoldItalic.ttf)
      format("ttf");
  font-weight: normal;
  font-style: normal;
}
.opensans-Extrabolditalic {
  font-family: opensans-extrabolditalic, Arial, Helvetica, sans-serif !important;
}

@font-face {
  font-family: "opensans-italic";
  src: url(assets/fonts/webfonts/custom-fonts/OpenSans-Italic/opensans-italic.woff2)
      format("woff2"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-Italic/opensans-italic.woff)
      format("woff"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-Italic.ttf) format("ttf");
  font-weight: normal;
  font-style: normal;
}

.OpenSans-Italic {
  font-family: opensans-italic, Arial, Helvetica, sans-serif !important;
}

@font-face {
  font-family: "opensans-light";
  src: url(assets/fonts/webfonts/custom-fonts/OpenSans-Light/opensans-light.woff2)
      format("woff2"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-Light/opensans-light.woff)
      format("woff"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-Light.ttf) format("ttf");
  font-weight: normal;
  font-style: normal;
}

.OpenSans-Light {
  font-family: opensans-light, Arial, Helvetica, sans-serif !important;
  font-weight: bolder;
  color: #9e9e9e;
}

@font-face {
  font-family: "opensans-lightitalic";
  src: url(assets/fonts/webfonts/custom-fonts/OpenSans-LightItalic/opensans-lightitalic.woff2)
      format("woff2"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-LightItalic/opensans-lightitalic.woff)
      format("woff"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-LightItalic.ttf)
      format("ttf");
  font-weight: normal;
  font-style: normal;
}

.OpenSans-LightItalic {
  font-family: opensans-lightitalic, Arial, Helvetica, sans-serif !important;
}

@font-face {
  font-family: "opensans-regular";
  src: url(assets/fonts/webfonts/custom-fonts/OpenSans-Regular/opensans-regular.woff2)
      format("woff2"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-Regular/opensans-regular.woff)
      format("woff"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-Regular.ttf) format("ttf");
  font-weight: normal;
  font-style: normal;
}

.OpenSans-Regular {
  font-family: opensans-regular, Arial, Helvetica, sans-serif !important;
  font-weight: 600;
}

@font-face {
  font-family: "opensans-semibold";
  src: url(assets/fonts/webfonts/custom-fonts/OpenSans-Semibold/opensans-semibold.woff2)
      format("woff2"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-Semibold/opensans-semibold.woff)
      format("woff"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-Semibold.ttf) format("ttf");
  font-weight: normal;
  font-style: normal;
}

.OpboldenSans-Semi {
  font-family: opensans-semibold, Arial, Helvetica, sans-serif !important;
}

@font-face {
  font-family: "opensans-semibolditalic";
  src: url(assets/fonts/webfonts/custom-fonts/OpenSans-SemiboldItalic/opensans-semibolditalic.woff2)
      format("woff2"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-SemiboldItalic/opensans-semibolditalic.woff)
      format("woff"),
    url(assets/fonts/webfonts/custom-fonts/OpenSans-SemiboldItalic.ttf)
      format("ttf");
  font-weight: normal;
  font-style: normal;
}

/* Tooltop CSS end */
.customers-tooltip {
  white-space: pre-line !important;
  float: left !important;

  .mdc-tooltip__surface {
    text-align: left !important;
    text-transform: uppercase !important;
  }
}
/* Tooltop CSS end */

/** Loader **/
.spinning {
  animation: spin 1.5s linear infinite;
  font-size: 70px;
  position: absolute;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.spinning2 {
  animation: spin2 4s linear infinite;
  font-size: 56px;
  position: absolute;
}

@keyframes spin2 {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.spinning3 {
  animation: spin 3s linear infinite;
  font-size: 40px;
  position: absolute;
}

.loading-data {
  position: absolute;
  height: 100%;
  width: 100%;
  ::ng-deep svg path {
    color: var(--navy) !important;
  }
}

/** Loader End **/
