mat-drawer-content {
  transition: var(--transitionTime) ease;
}

/** Button **/
.mat-mdc-button {
  outline: none !important;
  border: none !important;
}

.mat-mdc-button,
.mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: none !important;
}
.mat-mdc-button-ripple {
  display: none !important;
}

/** Button End **/

/** Input **/
.mdc-text-field--no-label:not(.mdc-text-field--textarea)
  .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  outline: none !important;
  border: 1px solid var(--border) !important;
  border-radius: 3px !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: #ffffff !important;
}
/** Input End**/

/** Checkbox **/
.mat-pseudo-checkbox {
  width: var(--largeFont) !important;
  height: var(--largeFont) !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  border-color: var(--navy) !important;
  background-color: var(--navy) !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
  background: var(--navy) !important;
}

.mdc-checkbox__checkmark-path {
  color: #ffffff !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate="true"]
  )
  ~ .mdc-checkbox__background {
  border-color: var(--navy) !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:not([disabled]):focus
  ~ .mdc-checkbox__ripple {
  opacity: 0 !important;
}

.mat-mdc-checkbox-ripple {
  display: none !important;
}

.mat-mdc-checkbox
  .mdc-checkbox:hover
  .mdc-checkbox__native-control:not([disabled])
  ~ .mdc-checkbox__ripple {
  display: none !important;
}
/** Checkbox End**/

.mat-mdc-card-content:first-child {
  padding-top: 0px !important;
}

.mat-mdc-cell {
  font-size: var(--smallFont);
}

.mat-mdc-select {
  font-size: var(--smallFont);
}

.mat-mdc-option {
  font-size: var(--smallFont);
}

.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0px !important;
}

.mat-mdc-tooltip-component {
  display: none !important;
}

.mat-mdc-option[aria-disabled="true"].contains-mat-select-search {
  margin-top: 0px !important;
  top: 0px !important;
}

.mat-mdc-optgroup-label {
  min-height: 30px !important;
  font-size: var(--smallFont) !important;
  padding-left: 10px !important;

  .mdc-list-item__primary-text {
    color: var(--grey) !important;
  }
}
